import React from "react"
import { css } from "styled-components"

import Layout from "../components/Layout"
import Container from "../components/Container"

const NotFoundPage = () => (
  <Layout>
    <Container
      css={css`
        padding-bottom: calc(120px + env(safe-area-inset-bottom));
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        `}
      >
        A keresett oldal nem található
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
